<template>
  <div
    v-if="link.title"
    :class="[
      'floating-cta',
      isVisible ? 'floating-cta--visible' : 'floating-cta--hidden'
    ]"
  >
    <form-button
      :background-color="backgroundColor"
      tag="recess-link"
      :sanity-link="link"
    >
      {{ link.title }}
    </form-button>
  </div>
</template>

<script>
import FormButton from '~/components/form/button.vue'
export default {
  name: 'FloatingCta',
  components: {
    FormButton
  },
  props: {
    link: {
      type: Object,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    backgroundColor: {
      type: String,
      default: undefined,
      required: false
    }
  }
}
</script>

<style>
.floating-cta {
  bottom: var(--y-gutter-sm);
  display: flex;
  padding-right: var(--x-gutter-sm);
  position: fixed;
  right: 0;
  transform: translateY(var(--y-gutter-sm));
  transition: opacity var(--fade-speed), transform var(--fade-speed);
  z-index: theme('zIndex.floatingCta');
}

.floating-cta--hidden {
  opacity: 0;
  pointer-events: none;
}

.floating-cta--visible {
  opacity: 1;
  transform: translateY(0);
}

.is-mobile .floating-cta {
  bottom: calc(var(--y-gutter-sm) / 2);
  padding: 0 var(--x-gutter-sm);
  width: 100%;
}

.is-mobile .floating-cta .button {
  width: 100%;
}
</style>
