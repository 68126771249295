<template>
  <p
    v-if="bp"
    :class="classProps"
    class="flex items-end justify-center 2xl:pt-y-gutter-sm"
  >
    <cta
      v-if="cta"
      tag="recess-link"
      :sanity-link="cta"
      fill
      class="relative z-above"
    >
      {{ cta.title || (cta.page ? cta.page.title : '') || 'Shop' }}
    </cta>
    <cta
      v-else
      tag="recess-link"
      to="/shop/sparkling-water/"
      fill
      class="relative z-above"
    >
      Shop
    </cta>
  </p>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SplashCta',
  props: {
    cta: {
      type: Object,
      default: undefined
    },
    classProps: {
      type: [String, Object, Array],
      default: undefined
    }
  },
  computed: {
    ...mapGetters('screen', {
      bp: 'breakpoint'
    }),
    small() {
      return true
    }
  }
}
</script>
