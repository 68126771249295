<template>
  <blockquote class="press-item">
    <recess-link v-if="url" :href="url" class="link">
      <div class="press-item__outlet">
        <recess-image
          v-if="src"
          :src="src"
          :alt="title"
          :width="width"
          :height="height"
          class="press-item__image"
        />
        <b v-else class="press-item__title">{{ title }}</b>
      </div>
      <p v-if="quote" class="press-item__quote max-w-96">
        &ldquo;{{ quote }}&rdquo;
      </p>
    </recess-link>
    <template v-else>
      <div class="press-item__outlet">
        <recess-image
          v-if="src"
          :src="src"
          :alt="title"
          class="press-item__image"
        />
        <b v-else class="press-item__title">{{ title }}</b>
      </div>
      <p v-if="quote" class="press-item__quote max-w-96">
        &ldquo;{{ quote }}&rdquo;
      </p>
    </template>
  </blockquote>
</template>

<script>
export default {
  name: 'PressItem',

  props: {
    title: {
      default: undefined,
      type: String
    },
    quote: {
      default: undefined,
      type: String
    },
    src: {
      default: undefined,
      type: String
    },
    url: {
      default: undefined,
      type: String
    },
    width: {
      default: undefined,
      type: Number
    },
    height: {
      default: undefined,
      type: Number
    }
  }
}
</script>

<style>
.press-item {
  --press-gutter: 2em;
  background-image: radial-gradient(
    closest-side,
    var(--semiopaque-color),
    var(--transparent-color)
  );
  font-size: theme('fontSize.base');
  line-height: theme('lineHeight.snug');
  padding-bottom: var(--press-gutter);
  padding-top: var(--press-gutter);
  pointer-events: auto;
  text-align: center;
}

.press-item__outlet {
  align-items: center;
  display: flex;
  height: 3em;
  justify-content: center;
  margin-bottom: 1.5em;
  position: relative;
  width: 100%;
}

.press-item__image,
.press-item__image .image__img {
  display: block;
  height: 100%;
}

.press-item__title {
  color: var(--text-color); /* override link color */
  font-size: theme('fontSize.base');
  font-weight: theme('fontWeight.medium');
  text-transform: uppercase;
}

@screen sm {
  .press-item {
    font-size: theme('fontSize.sm');
  }
}
</style>
