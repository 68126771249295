<template>
  <video
    ref="video"
    :width="width"
    :height="height"
    muted
    playsinline
    :autoplay="autoplay"
    :loop="loop"
    preload="auto"
    v-on="listeners"
  >
    <source :src="srcWebm" type="video/webm; codecs=vp9,opus" />
    <!-- required for Safari 13 -->
    <source :src="srcMov" type="video/quicktime; codecs=hevc" />
    <!-- required for Safari 14 -->
    <source :src="srcMov" type="video/quicktime; codecs=hvc1" />
  </video>
</template>

<script>
export default {
  name: 'RVideo',
  props: {
    // srcRoot is used for hardcoded non-sanity videos
    srcRoot: {
      type: String,
      required: false,
      default: undefined
    },

    webmUrl: {
      type: String,
      required: false,
      default: undefined
    },

    movUrl: {
      type: String,
      required: false,
      default: undefined
    },

    width: {
      type: [Number, String],
      required: true
    },

    height: {
      type: [Number, String],
      required: true
    },

    loop: {
      type: Boolean,
      default: false
    },

    autoplay: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    srcMov() {
      const { movUrl, srcRoot } = this
      if (movUrl) {
        return movUrl
      }
      if (!srcRoot) {
        throw new Error('Missing srcRoot')
      }
      return srcRoot + '.mov'
    },

    srcWebm() {
      const { webmUrl, srcRoot } = this
      if (webmUrl) {
        return webmUrl
      }
      if (!srcRoot) {
        throw new Error('Missing srcRoot')
      }
      return srcRoot + '.webm'
    },

    listeners() {
      // pass through listeners to video element
      const { ...listeners } = this.$listeners
      return listeners
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$emit('mounted')
    })
  },

  methods: {
    load() {
      const { video } = this.$refs
      if (video) {
        video.load()
      }
    },

    pause() {
      const { video } = this.$refs
      if (video) {
        video.pause()
      }
    },

    play() {
      const { video } = this.$refs
      if (video) {
        video.play()
      }
    }
  }
}
</script>
