<template>
  <div :class="classes" class="splash-video">
    <RVideo
      ref="closed"
      :width="640"
      :height="610"
      class="splash-video__video splash-video__video--closed"
      :src-root="
        samplerSplash
          ? '/video/splash-opening-scrubbed'
          : '/video/splash-opening'
      "
      @mounted.once="videoMounted('closed')"
      @loadedmetadata.once="videoProgress('closed')"
      @progress="videoProgress('closed')"
      @ended.once="closedEnded"
    />
    <RVideo
      ref="opened"
      :width="640"
      :height="610"
      class="splash-video__video splash-video__video--opened"
      :src-root="
        samplerSplash ? '/video/splash-open-scrubbed' : '/video/splash-open'
      "
      loop
      @progress="videoProgress('opened')"
      @loadedmetadata.once="videoProgress('opened')"
      @mounted.once="videoMounted('opened')"
    />
  </div>
</template>

<script>
import RVideo from '../r-video'

export default {
  name: 'SplashVideo',

  components: {
    RVideo
  },

  data() {
    return {
      videosLoaded: [],
      ready: false,
      open: false,
      played: false,
      samplerSplash: process.env.samplerSplash
    }
  },

  computed: {
    classes() {
      return {
        'is-ready': this.ready,
        'is-loaded': this.loaded,
        'is-open': this.open,
        'is-played': this.played
      }
    },
    loaded() {
      return this.videosLoaded.length === 2
    }
  },

  watch: {
    loaded(loaded) {
      if (loaded) {
        this.played = true
        this.playVideo(this.$refs.closed)
      }
    },
    videosLoaded(videos) {
      if (videos.length === 2 && !this.ready) {
        this.ready = true
      }
    },
    open(open) {
      if (open) {
        this.playVideo(this.$refs.opened)
      }
    }
  },

  methods: {
    playVideo(video) {
      video.play()
    },

    pauseVideos() {
      const closed = this.$refs.closed
      const opened = this.$refs.opened
      if (closed && opened) {
        closed.pause()
        opened.pause()
      }
    },

    videoMounted(video) {
      // we can't rely on video 'preload' attr working
      // firefox can and was ignoring it so we do it manually
      this.$refs[video].load()

      // the video can be fully loaded and not emit any of the Media Element
      // events upon mounting so we need to check if it's been loaded already
      this.videoProgress(video)
    },

    videoProgress(video) {
      const videoRef = this.$refs[video]
      if (!videoRef) {
        // video ref isn't ready yet
        return
      }

      const videoElement = videoRef.$el

      // the duration can be NaN if the meta data hasn't loaded
      // the buffered length must be greater than 0 to check end(0)
      const isFullyLoaded =
        videoElement.duration > 0 &&
        videoElement.buffered.length > 0 &&
        videoElement.buffered.end(0) === videoElement.duration

      if (isFullyLoaded) {
        this.videosLoaded.push(video)
      }
    },

    closedEnded() {
      this.open = true
    }
  }
}
</script>

<style>
.splash-video {
  position: relative;
  user-select: none;
}

.splash-video::before {
  content: '';
  display: block;
  padding-top: 106%;
}

.splash-video__image,
.splash-video__videos,
.splash-video__video {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.splash-video__image {
  display: block;
}

.splash-video__video {
  opacity: 0;
  transition: opacity var(--hover-speed);
  z-index: 2;
}

.is-ready .splash-video__video--closed {
  opacity: 1;
}

.is-open .splash-video__video--closed {
  opacity: 0;
  transition: opacity var(--hover-speed) var(--hover-speed);
}

.is-open .splash-video__video--opened {
  opacity: 1;
}
</style>
