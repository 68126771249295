<template>
  <div v-if="press.length > 0" class="press-gallery">
    <gallery
      :slides="press"
      :options="galleryOptions"
      class="press-gallery__gallery"
    >
      <div
        v-for="(slide, i) in press"
        :key="`press-slide-${i}`"
        class="press-gallery__slide swiper-slide"
      >
        <press-item v-bind="slide" class="press-gallery__item" />
      </div>
    </gallery>
  </div>
</template>

<script>
import PressItem from './press-item.vue'

export default {
  name: 'PressGallery',

  components: {
    PressItem
  },

  props: {
    press: {
      default: () => [],
      type: Array
    }
  },

  data: () => ({
    galleryOptions: {
      autoHeight: true
    }
  })
}
</script>

<style>
.press-gallery {
  background-image: linear-gradient(
    180deg,
    var(--transparent-color) 0%,
    var(--semiopaque-color) 50%,
    var(--transparent-color) 90%
  );
}

.press-gallery__slide {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 90%;
}

.press-gallery__item.press-item {
  background-image: none;
  flex: 0 1 auto;
  padding: var(--thumb-gutter);
  transform: scale(0.75);
  transition: transform var(--fade-speed);
}

.press-gallery__slide.is-active .press-gallery__item {
  transform: scale(1);
}

.press-gallery__slide .press__text {
  opacity: 0;
  transition: opacity var(--fade-speed), visibility var(--fade-speed);
  visibility: hidden;
}

.press-gallery__slide.is-active .press__text {
  opacity: 1;
  transition: opacity var(--fade-speed);
  visibility: visible;
}

.press-gallery .press-press__title {
  font-size: theme('fontSize.xs');
  padding-top: 1em;
}

@screen xs {
  .press-gallery__slide {
    width: 75%;
  }
}
</style>
