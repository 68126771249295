<template>
  <div
    v-if="bp"
    v-observe-visibility="visibilityChange"
    :class="classes"
    class="portal"
  >
    <template v-if="bp.md">
      <transition appear :css="false" @enter="enter" @after-enter="afterEnter">
        <div v-if="visibleOnce" ref="gateway" class="portal__gateway">
          <float
            v-if="product && product.thumb"
            ref="product"
            :stopped="!entered"
            reverse
            offset="-10%"
            class="portal__float"
          >
            <product-thumb
              :product="product"
              :title="product.title"
              hover-title
              class="portal__product"
            />
          </float>
          <component
            :is="product ? 'div' : 'float'"
            ref="steps"
            :stopped="entering"
            offset="-2%"
            class="portal__steps"
          >
            <svg
              :width="portalPathWidth"
              :height="portalPathHeight"
              :viewBox="`0 0 ${portalPathWidth} ${portalPathHeight}`"
              preserveAspectRatio="xMaxYMid meet"
              class="portal__svg"
            >
              <clipPath
                :id="portalPathId"
                :transform="portalPathTransform"
                clipPathUnits="objectBoundingBox"
              >
                <path :d="portalPathData" />
              </clipPath>
            </svg>
            <picture>
              <source
                data-src="~/assets/images/portal.webp"
                type="image/webp"
              />
              <source data-src="~/assets/images/portal.png" type="image/png" />
              <img
                ref="image"
                v-lazy-load
                :width="portalWidth"
                :height="portalHeight"
                data-src="~/assets/images/portal.png"
                alt
                class="portal__image"
              />
            </picture>
            <video-loop
              ref="video"
              :style="portalVideoStyle"
              :width="portalPathWidth"
              :height="portalPathHeight"
              :poster="require('~/assets/images/sky.jpg')"
              cover
              class="portal__video"
            >
              <source src="/video/sky.webm" type="video/webm" />
              <source src="/video/sky.mp4" type="video/mp4" />
            </video-loop>
          </component>
        </div>
      </transition>
    </template>
    <div v-else class="portal__gateway portal__gateway--sm">
      <float
        v-if="product && product.thumb"
        ref="product"
        reverse
        offset="-10%"
        class="portal__float"
      >
        <product-thumb
          :product="product"
          :title="product.title"
          hover-title
          class="portal__product"
        />
      </float>
      <div class="portal__steps">
        <svg
          :width="portalPathWidth"
          :height="portalPathHeight"
          :viewBox="`0 0 ${portalPathWidth} ${portalPathHeight}`"
          preserveAspectRatio="xMaxYMid meet"
          class="portal__svg"
        >
          <clipPath
            :id="portalPathId"
            :transform="portalPathTransform"
            clipPathUnits="objectBoundingBox"
          >
            <path :d="portalPathData" />
          </clipPath>
        </svg>
        <picture>
          <source data-src="~/assets/images/portal.webp" type="image/webp" />
          <source data-src="~/assets/images/portal.png" type="image/png" />
          <img
            ref="image"
            v-lazy-load
            :width="portalWidth"
            :height="portalHeight"
            data-src="~/assets/images/portal.png"
            alt
            class="portal__image"
          />
        </picture>
        <video-loop
          ref="video"
          :style="portalVideoStyle"
          :width="portalPathWidth"
          :height="portalPathHeight"
          :poster="require('~/assets/images/sky.jpg')"
          cover
          class="portal__video"
        >
          <source src="/video/sky.webm" type="video/webm" />
          <source src="/video/sky.mp4" type="video/mp4" />
        </video-loop>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import imagesLoaded from 'imagesloaded'
import { mapGetters } from 'vuex'
import VideoLoop from './video-loop'
import ProductThumb from '~/components/shop/products/product-thumb.vue'

const portalWidth = 640
const portalHeight = 1046
const portalPathWidth = 340
const portalPathHeight = 534
const portalPathData =
  'M0 502.41V157.96S15.32 0 155.2 0 340 157.96 340 157.96V534L0 502.41z'

export default {
  name: 'Portal',

  components: {
    ProductThumb,
    VideoLoop
  },

  props: {
    product: {
      default: undefined,
      type: Object
    }
  },

  data() {
    return {
      portalWidth,
      portalHeight,
      portalPathWidth,
      portalPathHeight,
      portalPathData,
      stepsWidth: 0,
      stepsHeight: 0,
      visible: false,
      visibleOnce: false,
      entering: true,
      entered: false
    }
  },

  computed: {
    ...mapGetters('screen', {
      bp: 'breakpoint'
    }),
    classes() {
      return {
        'portal--visible': this.bp && !this.bp.md,
        'is-visible': this.visible,
        'is-entered': this.entered
      }
    },
    portalPathId() {
      return 'portal-path'
    },
    portalPathTransform() {
      return `scale(${1 / portalPathWidth} ${1 / portalPathHeight})`
    },
    portalVideoStyle() {
      const id = this.portalPathId
      return {
        '-webkit-clip-path': `url(#${id})`,
        'clip-path': `url(#${id})`
      }
    }
  },

  watch: {
    entered(entered) {
      if (entered) {
        this.playVideo()
      }
    }
  },

  methods: {
    playVideo() {
      const video = this.$refs.video
      if (video) {
        video.play()
      }
    },
    pauseVideo() {
      const video = this.$refs.video
      if (video) {
        video.pause()
      }
    },
    visibilityChange(visible) {
      if (visible) {
        this.visible = visible
        if (this.visibleOnce) {
          this.playVideo()
        } else {
          this.visibleOnce = true
        }
      } else {
        this.visible = false
        if (this.visibleOnce) {
          this.pauseVideo()
        }
      }
    },
    async enter(el, done) {
      const vm = this
      const gateway = this.$refs.gateway
      const video = this.$refs.video
      const product = this.$refs.product
      let productEl
      const tl = gsap.timeline({ paused: true, onComplete: done })
      if (video) {
        const buffer = video.buffered()
        if (!buffer) {
          await new Promise(resolve => {
            video.$once('loaded', resolve)
          })
        }
      }
      if (product) {
        productEl = product.$el
        tl.set(productEl, {
          autoAlpha: 0,
          transform: 'translateX(100%) scale(0.8)'
        })
      }
      if (gateway) {
        await new Promise(resolve => imagesLoaded(gateway, resolve))
        tl.set(gateway, {
          autoAlpha: 0,
          x: '150%',
          scale: 0.8
        })
        tl.to(gateway, {
          autoAlpha: 1,
          x: '0%',
          scale: 1,
          delay: 0.5,
          duration: 2.5,
          ease: 'power2.out',
          onComplete() {
            vm.entering = false
          }
        })
      }
      if (product) {
        tl.to(
          productEl,
          {
            autoAlpha: 1,
            transform: 'translateX(0%) scale(1)',
            duration: 1
          },
          '-=1.5'
        )
      }
      tl.play()
    },
    afterEnter() {
      this.entered = true
    }
  }
}
</script>

<style lang="scss">
.portal,
.portal__image {
  max-height: calc(var(--win-height) / 2);
}

.portal {
  opacity: 0;
  pointer-events: none;
  position: relative;
  text-align: right;
  user-select: none;
  visibility: hidden;
}

.portal::before {
  content: '';
  display: block;
  padding-top: calc((1046 / 640) * 100%);
}

.portal.is-visible {
  opacity: 1;
  visibility: visible;
}

.portal.is-entered {
  transition: opacity var(--fade-speed), visibility var(--fade-speed);
}

.portal.is-visible.is-entered {
  transition: opacity var(--fade-speed);
}

.portal__gateway {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateX(150%) scale(0.8);
  width: 100%;
}

.portal__gateway--sm {
  opacity: 1;
}

.portal--visible .portal__gateway {
  transform: none;
}

.portal__float {
  height: 40%;
  left: 10%;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: scale(0.8);
  visibility: hidden;
  width: 40%;
  z-index: 4;
}

.portal--visible .portal__float {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.portal__product.product-thumb {
  height: 100%;
  padding: 0;
  pointer-events: auto;
  transform: rotate(-10deg);
  width: 100%;
}

.portal__product .thumb__title {
  display: none;
}

.portal__steps {
  display: inline-block;
  position: relative;
}

.portal__svg,
.portal__image,
.portal__video {
  display: block;
  flex: 0 0 auto;
  pointer-events: none;
}

.portal__svg,
.portal__video {
  height: 50%;
  position: absolute;
  right: 4%;
  top: 2%;
  width: 52%;
}

.portal__svg {
  z-index: 2;
}

.portal__image {
  height: auto;
  max-width: 100%;
  position: relative;
  width: auto;
  z-index: 3;
}

@screen md {
  .portal__product .thumb__title {
    display: block;
  }
}
</style>
