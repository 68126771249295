<template>
  <img :width="width" :height="height" :src="src" />
</template>

<script>
// this is somewhat arbitrary
const heroImageMaxHeight = 2048
// this exists because the recess-image class was doing too much magic
// (absolutely positioning the image rather than using instrinsic values)
// for the css grid implementation to work
export default {
  name: 'SplashImage',
  props: {
    image: {
      type: Object,
      default: undefined,
      required: true
    }
  },
  computed: {
    src() {
      if (!this.image) {
        return
      }

      return this.$sanityImage(this.image)
        .width(heroImageMaxHeight)
        .auto('format')
        .url()
    },
    width() {
      return this.image.metadata.width
    },
    height() {
      return this.image.metadata.height
    }
  }
}
</script>

<style scoped>
img {
  max-height: 100%;
  object-fit: contain;
}
</style>
