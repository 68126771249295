<template>
  <div v-if="bp && press.length" class="press">
    <press-row v-if="bp.lg" :reverse="reverse" :press="items" />
    <press-gallery v-else :press="items" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PressRow from './press-row.vue'
import PressGallery from './press-gallery.vue'

const DEFAULT_WIDTH = 320
const HEIGHT = 42

export default {
  name: 'Press',

  components: {
    PressRow,
    PressGallery
  },

  props: {
    press: {
      default: () => [],
      type: Array
    },
    reverse: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    items: []
  }),

  async fetch() {
    const items = this.press
    const imageUrls = []
    const getImageUrls = async images => {
      for (const { image } of images) {
        const url = await this.getImageUrl({
          image,
          width: this.computeWidth(image),
          height: HEIGHT
        })
        imageUrls.push(url)
      }
    }
    if (!items || !items.length) {
      return
    }
    await getImageUrls(items)

    this.items = items.map((s, i) => ({
      ...s,
      src: imageUrls[i],
      width: this.computeWidth(s.image),
      height: HEIGHT
    }))
  },

  computed: {
    ...mapGetters('screen', {
      bp: 'breakpoint'
    })
  },

  methods: {
    ...mapActions({
      getImageUrl: 'getImageUrl'
    }),
    computeWidth(image) {
      if (!image?.metadata?.aspectRatio) {
        return DEFAULT_WIDTH
      }

      return Math.round(HEIGHT * image?.metadata?.aspectRatio)
    }
  }
}
</script>

<style>
.press {
  --text-color: theme('colors.white');
}
</style>
