<template>
  <div class="press-row">
    <marquee always :reverse="reverse">
      <div class="press-row__marquee">
        <press-item
          v-for="(item, i) in press"
          :key="`press-${i}`"
          v-bind="item"
          class="press-row__item"
        />
      </div>
    </marquee>
  </div>
</template>

<script>
import PressItem from '~/components/press/press-item.vue'

export default {
  name: 'PressRow',

  components: {
    PressItem
  },

  props: {
    press: {
      default: () => [],
      type: Array
    },
    reverse: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>
.press-row {
  --row-gutter: 1.5em;
  --row-item-size: theme('width.64');
}

.press-row__marquee {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.press-row__item {
  flex: 0 0 auto;
  margin-left: var(--row-gutter);
  margin-right: var(--row-gutter);
  white-space: normal;
  width: var(--row-item-size);
}

@screen sm {
  .press-row {
    --row-gutter: 2em;
  }
}

@screen 2xl {
  .press-row {
    --row-item-size: theme('width.72');
  }
}
</style>
