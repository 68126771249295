<template>
  <div
    v-observe-visibility="{ once: true, callback: visibilityChange }"
    class="candy-planets"
  >
    <template v-if="visible">
      <float class="candy-planets__float">
        <candy-planets-video />
      </float>
    </template>
  </div>
</template>

<script>
import RVideo from '../r-video'
import CandyPlanetsVideo from './candy-planets-video'

export default {
  name: 'CandyPlanets',

  components: {
    CandyPlanetsVideo,
    // VideoLoop is required for the funcitonal candy-planets-video
    // eslint-disable-next-line vue/no-unused-components
    RVideo
  },

  data: () => ({
    visible: false
  }),

  methods: {
    visibilityChange(visible) {
      this.visible = true
    }
  }
}
</script>

<style>
.candy-planets {
  position: relative;
}

.candy-planets::before {
  content: '';
  display: block;
  padding-top: 79.68%;
}

.candy-planets__float,
.candy-planets .candy-planets-video,
.candy-planets .candy-planets-sequence {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
