<template>
  <div v-if="slides.length > 0" class="ingredient-gallery">
    <gallery
      :slides="slides"
      :options="galleryOptions"
      :cursor-over="cursorOver"
      class="ingredient-gallery__gallery"
    >
      <div
        v-for="(ingredient, i) in slides"
        :key="`ingredient-slide-${i}`"
        :class="slideClass"
      >
        <ingredient
          v-bind="ingredient"
          :float-offset="i % 2 ? '5%' : '-8%'"
          :float-speed="i % 2 ? 6 : 10"
          :float-reverse="i % 2 ? true : false"
          :use-long-description="longDescriptions"
          class="ingredient-gallery__ingredient"
          swiper
          image-class="swiper-lazy"
        />
      </div>
    </gallery>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Ingredient from './ingredient.vue'
import getCBDFilter from '~/util/get-cbd-filter'

export default {
  name: 'IngredientGallery',

  components: {
    Ingredient
  },

  props: {
    galleryOptions: {
      default: () => ({
        autoHeight: true,
        slidesPerView: 'auto'
      }),
      type: Object
    },
    ingredients: {
      default: () => [],
      type: Array
    },
    longDescriptions: {
      default: false,
      type: Boolean
    },
    minimizeInactiveSlides: {
      default: true,
      type: Boolean
    },
    cursorOver: {
      default: true,
      type: Boolean
    }
  },

  data: () => ({
    slides: [],
    isCBDSafe: getCBDFilter(process.env.nonCBDSite)
  }),

  async fetch() {
    const ingredients = this.filteredIngredients
    const imageUrls = []
    const getImageUrls = async images => {
      for (const { image, imageAlt } of images) {
        let url
        if (process.env.samplerSplash && imageAlt) {
          url = await this.getImageUrl({
            image: imageAlt,
            width: 640,
            height: 640
          })
        } else {
          url = await this.getImageUrl({
            image,
            width: 640,
            height: 640
          })
        }
        imageUrls.push(url)
      }
    }
    if (!ingredients || !ingredients.length) {
      return
    }
    await getImageUrls(ingredients)
    const slides = ingredients.map((s, i) => ({
      ...s,
      imageSrc: imageUrls[i]
    }))
    this.slides = slides
  },

  computed: {
    filteredIngredients() {
      return this.ingredients.filter(this.isCBDSafe)
    },
    slideClass() {
      const classes = ['ingredient-gallery__slide swiper-slide']
      if (this.minimizeInactiveSlides) {
        classes.push('ingredient-gallery__slide--minimize-inactive')
      }
      return classes.join(' ')
    }
  },

  methods: {
    ...mapActions({
      getImageUrl: 'getImageUrl'
    })
  }
}
</script>

<style>
.ingredient-gallery__slide {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 60%;
}

.ingredient-gallery__ingredient {
  flex: 0 1 auto;
  padding: var(--thumb-gutter);
}

.ingredient-gallery__slide--minimize-inactive .ingredient-gallery__ingredient {
  transform: scale(0.75);
  transition: transform var(--fade-speed);
}

.ingredient-gallery__slide--minimize-inactive.is-active
  .ingredient-gallery__ingredient {
  transform: scale(1);
}

.ingredient-gallery .ingredient-ingredient__title {
  font-size: theme('fontSize.xs');
  padding-top: 1em;
}

.ingredient-gallery__slide--minimize-inactive .ingredient__text {
  opacity: 0;
  transition: opacity var(--fade-speed), visibility var(--fade-speed);
  visibility: hidden;
}

.ingredient-gallery__slide--minimize-inactive.is-active .ingredient__text {
  opacity: 1;
  transition: opacity var(--fade-speed);
  visibility: visible;
}

@screen sm {
  .ingredient-gallery__slide {
    width: 40%;
  }
}
</style>
