<template>
  <div class="ingredient-row container">
    <ingredient
      v-for="(ingredient, i) in ingredients"
      :key="`${ingredient._id}-${i}`"
      v-bind="ingredient"
      :float-offset="i % 2 ? '4%' : '-6%'"
      :float-speed="i % 2 ? 6 : 7"
      :float-reverse="i % 2 ? true : false"
      :use-long-description="longDescriptions"
      class="ingredient-row__ingredient"
    />
  </div>
</template>

<script>
import Ingredient from './ingredient.vue'

export default {
  name: 'IngredientRow',

  components: {
    Ingredient
  },

  props: {
    ingredients: {
      default: () => [],
      type: Array
    },
    longDescriptions: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>
.ingredient-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ingredient-row__ingredient {
  @apply px-2em mb-y-gutter-sm;
  flex: 0 1 auto;
  text-align: center;
  width: 100%;
}

@screen xs {
  .ingredient-row__ingredient {
    width: 50%;
  }
}

@screen lg {
  .ingredient-row__ingredient {
    width: 25%;
  }
}

@screen xl {
  .ingredient-row__ingredient {
    @apply px-4em;
  }
}
</style>
