<template>
  <div class="video-loop" :class="classes">
    <video
      ref="video"
      v-bind="$attrs"
      :poster="poster"
      class="video-loop__video"
      autoplay
      muted
      loop
      playsinline
      @loadedmetadata="loaded"
    >
      <slot>
        <source
          v-for="(source, i) in sources"
          :key="`source-${i}`"
          :src="source.src"
          :type="source.type"
        />
      </slot>
    </video>
  </div>
</template>

<script>
let Hls
if (process.client) {
  Hls = require('hls.js/dist/hls.light')
}

export default {
  name: 'VideoLoop',

  props: {
    sources: {
      default: () => [],
      type: Array
    },
    stream: {
      default: undefined,
      type: String
    },
    poster: {
      default: undefined,
      type: String
    },
    aspectRatio: {
      default: null,
      type: String
    },
    cover: {
      default: false,
      type: Boolean
    },
    contain: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    classes() {
      return {
        'video-loop--cover': this.cover,
        'video-loop--contain': this.contain
      }
    }
  },

  watch: {
    stream() {
      this.setupStream()
    }
  },

  mounted() {
    if (this.stream) {
      this.setupStream()
    }
  },

  methods: {
    setupStream() {
      const video = this.$refs.video
      const stream = this.stream
      if (video && Hls.isSupported()) {
        const hls = new Hls()
        hls.loadSource(stream)
        hls.attachMedia(video)
        hls.on(Hls.Events.MANIFEST_PARSED, function() {
          if (video.autoplay) {
            video.play()
          }
        })
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = stream
      }
    },
    loaded() {
      this.$emit('loaded')
    },
    buffered() {
      const video = this.$refs.video
      if (video) {
        return video.readyState >= 3
      }
    },
    play() {
      const video = this.$refs.video
      if (video) {
        video.play()
      }
    },
    pause() {
      const video = this.$refs.video
      if (video) {
        video.pause()
      }
    }
  }
}
</script>

<style>
.video-loop--cover .video-loop__video {
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-loop--contain .video-loop__video {
  object-fit: contain;
}
</style>
