<template>
  <div v-if="image" class="scroll-indicator">
    <float offset="-15%">
      <sanity-media :image="image" :width="width" :height="height" />
    </float>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ScrollIndicator',
  computed: {
    ...mapState({
      image: state => state.settings.scrollIndicator?.image
    }),
    width() {
      return this.image.asset.metadata.dimensions.width
    },
    height() {
      return this.image.asset.metadata.dimensions.height
    }
  }
}
</script>

<style scoped>
.scroll-indicator {
  height: 100%;
  pointer-events: none;
  width: 100%;
}

.scroll-indicator >>> .float {
  height: 100%;
}

.scroll-indicator >>> .image {
  display: flex;
  justify-content: center;
  max-height: 100%;
}
</style>
