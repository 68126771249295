<template>
  <div class="featured-product">
    <sanity-media
      v-if="backgroundImage"
      class="featured-product__background-image"
      :cover="true"
      :fluid="true"
      :image="backgroundImage"
      :max-width="2400"
      :max-height="2400"
    />

    <div
      class="grid items-center pt-24 pb-8 md:py-y-gutter-lg content-evenly gap-y-4 gap-x-20 md:grid-cols-2 featured-product__container"
    >
      <div class="text-center md:text-left featured-product__content">
        <div class="relative text-lg xl:text-xl 2xl:text-2xl">
          <h1
            class="heading heading-lg mb-em featured-product__heading"
            :style="{ color: ctaTextColor.hex }"
          >
            {{ title }}
          </h1>
          <sanity-blocks
            v-if="text"
            :blocks="text"
            class="featured-product__text"
            :style="{ color: ctaTextColor.hex }"
          />
        </div>
        <cta
          v-if="cta"
          tag="recess-link"
          :sanity-link="cta"
          fill
          :text-color="ctaButtonTextColor.hex"
          :background-color="ctaButtonBackgroundColor.hex"
          class="featured-product__cta"
        >
          {{ cta.title || (cta.page ? cta.page.title : '') || 'Shop' }}
        </cta>
      </div>
      <div class="relative featured-product__image">
        <sanity-media
          v-if="image"
          class="max-h-full"
          :contain="true"
          :image="image"
          :max-width="1600"
          :max-height="1600"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturedProduct',

  components: {},

  props: {
    title: {
      default: undefined,
      type: String
    },
    text: {
      default: undefined,
      type: Array
    },
    cta: {
      default: undefined,
      type: Object
    },
    ctaTextColor: {
      default: () => ({
        hex: undefined
      }),
      type: Object
    },
    ctaButtonTextColor: {
      default: () => ({
        hex: undefined
      }),
      type: Object
    },
    ctaButtonBackgroundColor: {
      default: () => ({
        hex: undefined
      }),
      type: Object
    },
    image: {
      default: undefined,
      type: Object
    },
    backgroundImage: {
      default: undefined,
      type: Object
    }
  }
}
</script>

<style>
.featured-product {
  display: flex;
  min-height: var(--win-height);
  overflow: hidden;
  position: relative;
}

.featured-product__container {
  margin: 0 var(--container-gutter);
}

@screen md {
  .featured-product__container {
    margin-right: calc(var(--container-gutter, 0) / 4);
  }
}

.featured-product__heading {
  text-wrap: balance;
}

@media (max-width: theme('screens.sm')) {
  .featured-product__heading {
    font-size: calc(0.66 * var(--heading-size));
  }
}

.featured-product__text {
  margin-bottom: calc(var(--y-gutter-sm) / 2);
  text-wrap: pretty;
}

@screen md {
  .featured-product__text {
    margin-bottom: var(--y-gutter-sm);
  }
}

.featured-product__cta::before {
  content: '';
  inset: 0;
  position: absolute;
  z-index: 1;
}

.featured-product__cta .cta__content {
  padding: 1.125em 1.69em;
}

@screen md {
  .featured-product__cta .cta__content {
    padding: var(--cta-gutter);
  }
}
</style>
