<template functional>
  <div
    :ref="data.ref"
    :style="[data.style, data.staticStyle]"
    :class="[data.class, data.staticClass, { 'is-static': props.static }]"
    class="rainbow-gradient"
    aria-hidden="true"
  >
    <div class="rainbow-gradient__image" />
    <div v-if="!props.static" class="rainbow-gradient__image" />
  </div>
</template>

<script>
export default {
  name: 'RainbowGradient',

  functional: true,

  props: {
    static: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>
@keyframes rainbow-fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.rainbow-gradient__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.has-webp-support .rainbow-gradient__image {
  background-image: url('/rainbow-gradient.webp');
}

.has-no-webp-support .rainbow-gradient__image {
  background-image: url('/rainbow-gradient.jpg');
}

.rainbow-gradient__image:nth-child(1) {
  z-index: 2;
}

.has-webp-support .rainbow-gradient__image:nth-child(1),
.has-no-webp-support .rainbow-gradient__image:nth-child(1) {
  animation: rainbow-fade 3s linear infinite alternate forwards;
}

.is-mobile .rainbow-gradient__image:nth-child(1) {
  display: none;
}

.rainbow-gradient__image:nth-child(2) {
  transform: scale(-1, -1);
}

.is-mobile .rainbow-gradient__image:nth-child(2) {
  transform: none;
}

.rainbow-gradient.is-static .rainbow-gradient__image {
  display: block;
  opacity: 1;
}

.has-webp-support .rainbow-gradient.is-static .rainbow-gradient__image,
.has-no-webp-support .rainbow-gradient.is-static .rainbow-gradient__image {
  animation: none;
}
</style>
