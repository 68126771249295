<template>
  <div :style="style" class="landing-splash">
    <div v-if="notTop" class="landing-splash__waves">
      <marquee
        class="absolute left-0 w-full -mb-px bottom-full"
        speed="16"
        fixed-speed
        reverse
      >
        <div class="landing-splash__wave-group">
          <wave
            v-for="waveIndex in waves"
            :key="waveIndex"
            :color="waveColor"
          />
        </div>
      </marquee>
      <div class="landing-splash__waves--buffer"></div>
    </div>

    <rainbow-gradient
      class="absolute top-0 left-0 w-full landing-splash__header"
      :class="{ 'landing-splash__header--not-top': notTop }"
    />

    <div
      class="relative grid pt-header-height landing-splash__grid landing-splash__header z-2above "
      :class="{
        'landing-splash__grid--vertical': hasVerticalMdLayout,
        'landing-splash__grid--horizontal': !hasVerticalMdLayout,
        'landing-splash__header--not-top': notTop,
        container: !hasVerticalMdLayout
      }"
    >
      <div
        v-if="bp"
        class="flex items-end justify-center row-start-1 row-end-2 pb-2 splash__title-wrapper"
        :class="
          hasVerticalMdLayout
            ? 'container'
            : 'md:col-start-1 md-col-end-2 md:row-start-2 md:row-end-3'
        "
      >
        <div class="self-stretch">
          <div
            class="flex flex-col justify-end h-full text-lg xl:text-xl 2xl:text-2xl"
            :class="{ 'text-center': hasVerticalMdLayout }"
          >
            <div>
              <h1 v-if="hasVerticalMdLayout" class="heading heading-lg mb-em">
                {{ title }}
              </h1>
              <h1 v-else class="mb-em">
                <span class="font-blank">{{ title }}</span>
                <sanity-media
                  v-if="titleImage"
                  :image="titleImage"
                  not-lazy
                  class="landing-splash__title-image"
                  @loaded="titleLoaded"
                />
                <title-image v-else class="landing-splash__title-image" />
              </h1>
              <sanity-blocks v-if="text" :blocks="text" />
            </div>
            <splash-cta
              :cta="cta"
              :class-props="[
                hasVerticalMdLayout ? null : 'md:block',
                'hidden',
                'py-4em'
              ]"
            />
          </div>
        </div>
      </div>
      <div
        class="relative flex justify-center"
        :class="
          hasVerticalMdLayout
            ? null
            : 'md:col-start-2 md:col-end-3 md:row-start-2 md:row-end-3'
        "
      >
        <div
          v-if="hero"
          :class="contentClasses"
          class="relative flex flex-col items-center justify-center landing-splash__hero md:px-8"
        >
          <splash-image v-if="bp" :image="hero" />
          <recess-link
            v-if="cta"
            class="absolute invisible w-full h-full"
            :sanity-link="cta"
          >
          </recess-link>
        </div>
        <div
          v-else-if="hasCmsVideo"
          :class="contentClasses"
          class="relative flex flex-col landing-splash__cms-video"
        >
          <fade>
            <div
              class="absolute inset-0 flex flex-col items-center justify-center flex-grow w-full h-full overflow-hidden"
            >
              <r-video
                :webm-url="videoWebm.asset.url"
                :mov-url="videoMov.asset.url"
                autoplay
                loop
                height="auto"
                width="auto"
              />
            </div>
          </fade>
        </div>
        <div
          v-else
          :class="contentClasses"
          class="landing-splash__video md:w-5/6 max-w-192"
        >
          <fade>
            <splash-video ref="video" />
          </fade>
        </div>
      </div>
      <splash-cta
        :cta="cta"
        small
        :class-props="[
          hasVerticalMdLayout ? null : 'md:hidden',
          'text-center py-3 md:pt-0'
        ]"
      />
      <div class="flex items-end row-start-4 pb-1 col-span-full md:row-start-4">
        <scroll-indicator />
      </div>
    </div>
    <gradient
      :colors="colors"
      force-gradient
      class="landing-splash__gradient animation-patch z-above"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { times } from 'lodash'
import SplashVideo from './splash-video.vue'
import SplashCta from './splash-cta.vue'
import SplashImage from './splash-image.vue'
import RainbowGradient from '~/components/rainbow-gradient.vue'
import TitleImage from '~/assets/images/splash-title.svg?inline'
import ScrollIndicator from '~/components/scroll-indicator.vue'
import RVideo from '~/components/r-video'
import Wave from '~/components/wave'

const numberOfWaves = 6

export default {
  name: 'Splash',

  components: {
    RainbowGradient,
    SplashVideo,
    SplashCta,
    SplashImage,
    TitleImage,
    ScrollIndicator,
    RVideo,
    Wave
  },

  props: {
    title: {
      default: 'An antidote to modern times',
      type: String
    },
    titleImage: {
      default: undefined,
      type: Object
    },
    text: {
      default: null,
      type: Array
    },
    cta: {
      default: undefined,
      type: Object
    },
    hero: {
      default: undefined,
      type: Object
    },
    videoWebm: {
      type: Object,
      required: false,
      default: undefined
    },
    videoMov: {
      type: Object,
      required: false,
      default: undefined
    },
    hasVerticalMdLayout: {
      type: Boolean,
      default: false
    },
    notTop: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loaded: false,
      color: 'var(--background-color)',
      samplerSplash: process.env.samplerSplash,
      waves: times(numberOfWaves),
      waveColor: 'var(--background-color)'
    }
  },

  computed: {
    ...mapGetters('screen', {
      transparentColor: 'transparentColor',
      solidColor: 'backgroundColor',
      bp: 'breakpoint'
    }),
    colors() {
      return [this.transparentColor, this.solidColor]
    },
    style() {
      return {
        backgroundColor: this.color,
        '--hero-media-row-height': this.heroMediaRowHeight
      }
    },
    contentClasses() {
      return ['landing-splash__content', 'w-full', 'md:py-2']
    },
    hasCmsVideo() {
      return !!this.videoWebm && !!this.videoMov
    },
    heroMediaRowHeight() {
      const height = this.hero?.metadata?.height
      return height ? `${this.heroImageMaxHeight}px` : '1fr'
    }
  },

  methods: {
    titleLoaded() {
      this.loaded = true
    }
  }
}
</script>

<style>
.landing-splash {
  --landing-splash-wave-height: 12px;
  position: relative;
}

.landing-splash__grid {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: auto minmax(50px, max-content) auto 2em;
}

.landing-splash__header {
  height: calc(var(--win-height) - 32px);
}

.landing-splash__header--not-top {
  height: calc(var(--win-height) + 32px);
}

@screen md {
  .landing-splash__grid--horizontal {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 0.5fr minmax(0, auto) 1fr 2em;
  }

  .landing-splash__grid--vertical {
    grid-template-columns: minmax(0, 1fr);
  }
}

.landing-splash__title-image {
  display: block;
  width: 100%;
}

/* this is supposed to be available in tailwind */
.col-span-full {
  grid-column: 1 / -1;
}

.landing-splash__title-image svg,
.landing-splash__title-image img {
  height: auto;
  width: 100%;
}

.landing-splash__video {
  position: relative;
  user-select: none;
}

.landing-splash__video::before {
  content: '';
  display: block;
  padding-top: 108%;
}

.landing-splash__video .splash-video {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing-splash__video .splash-video::before {
  display: none;
}

.landing-splash__gradient {
  bottom: 0;
  height: var(--header-height);
  left: 0;
  position: absolute;
  width: 100%;
}

.landing-splash__wave-group {
  display: flex;
  flex-direction: row;
  height: var(--landing-splash-wave-height);
  width: 100vw;
}

.landing-splash__wave-group .wave--horizontal {
  flex: 1;
  min-width: auto;
}

.landing-splash__waves {
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: 50% 50%;
  width: 100%;
  z-index: 3;
}

.landing-splash__waves--buffer {
  background-color: var(--background-color);
  height: 100px;
  mask-image: linear-gradient(180deg, black, transparent);
}
</style>
