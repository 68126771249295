var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"landing-splash",style:(_vm.style)},[(_vm.notTop)?_c('div',{staticClass:"landing-splash__waves"},[_c('marquee',{staticClass:"absolute left-0 w-full -mb-px bottom-full",attrs:{"speed":"16","fixed-speed":"","reverse":""}},[_c('div',{staticClass:"landing-splash__wave-group"},_vm._l((_vm.waves),function(waveIndex){return _c('wave',{key:waveIndex,attrs:{"color":_vm.waveColor}})}),1)]),_vm._v(" "),_c('div',{staticClass:"landing-splash__waves--buffer"})],1):_vm._e(),_vm._v(" "),_c('rainbow-gradient',{staticClass:"absolute top-0 left-0 w-full landing-splash__header",class:{ 'landing-splash__header--not-top': _vm.notTop }}),_vm._v(" "),_c('div',{staticClass:"relative grid pt-header-height landing-splash__grid landing-splash__header z-2above",class:{
      'landing-splash__grid--vertical': _vm.hasVerticalMdLayout,
      'landing-splash__grid--horizontal': !_vm.hasVerticalMdLayout,
      'landing-splash__header--not-top': _vm.notTop,
      container: !_vm.hasVerticalMdLayout
    }},[(_vm.bp)?_c('div',{staticClass:"flex items-end justify-center row-start-1 row-end-2 pb-2 splash__title-wrapper",class:_vm.hasVerticalMdLayout
          ? 'container'
          : 'md:col-start-1 md-col-end-2 md:row-start-2 md:row-end-3'},[_c('div',{staticClass:"self-stretch"},[_c('div',{staticClass:"flex flex-col justify-end h-full text-lg xl:text-xl 2xl:text-2xl",class:{ 'text-center': _vm.hasVerticalMdLayout }},[_c('div',[(_vm.hasVerticalMdLayout)?_c('h1',{staticClass:"heading heading-lg mb-em"},[_vm._v("\n              "+_vm._s(_vm.title)+"\n            ")]):_c('h1',{staticClass:"mb-em"},[_c('span',{staticClass:"font-blank"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),(_vm.titleImage)?_c('sanity-media',{staticClass:"landing-splash__title-image",attrs:{"image":_vm.titleImage,"not-lazy":""},on:{"loaded":_vm.titleLoaded}}):_c('title-image',{staticClass:"landing-splash__title-image"})],1),_vm._v(" "),(_vm.text)?_c('sanity-blocks',{attrs:{"blocks":_vm.text}}):_vm._e()],1),_vm._v(" "),_c('splash-cta',{attrs:{"cta":_vm.cta,"class-props":[
              _vm.hasVerticalMdLayout ? null : 'md:block',
              'hidden',
              'py-4em'
            ]}})],1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative flex justify-center",class:_vm.hasVerticalMdLayout
          ? null
          : 'md:col-start-2 md:col-end-3 md:row-start-2 md:row-end-3'},[(_vm.hero)?_c('div',{staticClass:"relative flex flex-col items-center justify-center landing-splash__hero md:px-8",class:_vm.contentClasses},[(_vm.bp)?_c('splash-image',{attrs:{"image":_vm.hero}}):_vm._e(),_vm._v(" "),(_vm.cta)?_c('recess-link',{staticClass:"absolute invisible w-full h-full",attrs:{"sanity-link":_vm.cta}}):_vm._e()],1):(_vm.hasCmsVideo)?_c('div',{staticClass:"relative flex flex-col landing-splash__cms-video",class:_vm.contentClasses},[_c('fade',[_c('div',{staticClass:"absolute inset-0 flex flex-col items-center justify-center flex-grow w-full h-full overflow-hidden"},[_c('r-video',{attrs:{"webm-url":_vm.videoWebm.asset.url,"mov-url":_vm.videoMov.asset.url,"autoplay":"","loop":"","height":"auto","width":"auto"}})],1)])],1):_c('div',{staticClass:"landing-splash__video md:w-5/6 max-w-192",class:_vm.contentClasses},[_c('fade',[_c('splash-video',{ref:"video"})],1)],1)]),_vm._v(" "),_c('splash-cta',{attrs:{"cta":_vm.cta,"small":"","class-props":[
        _vm.hasVerticalMdLayout ? null : 'md:hidden',
        'text-center py-3 md:pt-0'
      ]}}),_vm._v(" "),_c('div',{staticClass:"flex items-end row-start-4 pb-1 col-span-full md:row-start-4"},[_c('scroll-indicator')],1)],1),_vm._v(" "),_c('gradient',{staticClass:"landing-splash__gradient animation-patch z-above",attrs:{"colors":_vm.colors,"force-gradient":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }