<template>
  <div v-if="bp && ingredients.length">
    <ingredient-row
      v-if="bp.md"
      :ingredients="ingredients"
      :long-descriptions="longDescriptions"
    />
    <ingredient-gallery
      v-else
      :ingredients="ingredients"
      :long-descriptions="longDescriptions"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IngredientRow from './ingredient-row.vue'
import IngredientGallery from './ingredient-gallery.vue'

export default {
  name: 'Products',

  components: {
    IngredientRow,
    IngredientGallery
  },

  props: {
    ingredients: {
      default: () => [],
      type: Array
    },
    longDescriptions: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    ...mapGetters('screen', {
      bp: 'breakpoint'
    })
  }
}
</script>
