<template>
  <div class="page--splash">
    <featured-product
      v-if="featuredProductEnabled"
      v-observe-visibility="{ callback: hideCta }"
      :title="featuredProductTitle"
      :text="featuredProductText"
      :cta="featuredProductCta"
      :image="featuredProductImage"
      :background-image="featuredProductBackgroundImage"
      :cta-button-text-color="featuredProductCtaButtonTextColor"
      :cta-button-background-color="featuredProductCtaButtonBackgroundColor"
      :cta-text-color="featuredProductCtaTextColor"
    />

    <splash
      id="header"
      v-observe-visibility="{ callback: hideCta }"
      :not-top="featuredProductEnabled"
      :has-vertical-md-layout="splashIsVerticalLayout"
      :title="splashTitle"
      :title-image="splashTitleImage"
      :text="splashText"
      :cta="splashCta"
      :hero="splashHero"
      :video-webm="splashVideoWebm"
      :video-mov="splashVideoMov"
    />

    <floating-cta
      v-if="floatingCta"
      :is-visible="ctaIsVisible"
      :link="floatingCta"
    />

    <div id="content" class="relative z-base">
      <page-section
        v-if="introText"
        class="container text-lg leading-snug text-center md:text-2xl 2xl:text-3xl"
        center
      >
        <div
          class="flex items-center justify-center min-h-win-h-3/4 py-x-gutter-lg md:min-h-win-h-1/2"
        >
          <div
            class="relative flex-initial xs:w-3/4 xs:mx-auto 2xl:w-2/3 4xl:w-1/2"
          >
            <h2 v-if="introTitle" class="text-blue mb-em heading">
              {{ introTitle }}
            </h2>

            <sanity-blocks :blocks="introText" />
          </div>
        </div>
      </page-section>

      <LazyHydrate when-idle>
        <page-section
          v-if="featuredSampler && featuredSampler.length > 0"
          v-observe-visibility="{ callback: showCta }"
          class="relative scheme-light"
        >
          <products
            use-common-ratio
            :products="featuredSampler"
            :title="samplerTitle"
            :subtitle="samplerSubtitle"
            float-down
            class="mt-gutter"
          />
        </page-section>
      </LazyHydrate>

      <LazyHydrate when-idle>
        <page-section
          v-if="featuredZeroproof && featuredZeroproof.length > 0"
          v-observe-visibility="{ callback: showCta }"
          class="relative scheme-light"
        >
          <products
            use-common-ratio
            :products="featuredZeroproof"
            :title="zeroproofTitle"
            :subtitle="zeroproofSubtitle"
            float-down
            :min-product-slots="minProductSlots"
            class="mt-gutter"
          />
        </page-section>
      </LazyHydrate>

      <color-section
        v-if="moodBackgroundHexColors && moodWaterProducts.length > 0"
        opaque
        bounded
        force-gradient
        :background-colors="
          productOverBackgroundColors || moodBackgroundHexColors
        "
      >
        <marquee-background
          :background-images="moodProductCategory.backgroundImages"
        />
        <page-section
          v-if="moodWaterProducts && moodWaterProducts.length > 0"
          class="relative scheme-light"
        >
          <products
            :products="moodWaterProducts"
            :min-product-slots="minProductSlots"
            float-down
            :title="moodWaterTitle"
            :subtitle="moodWaterSubtitle"
            use-common-ratio
            class="mt-gutter"
            @over="over"
            @out="out"
          />
          <ingredients
            :v-if="moodWaterIngredientsWindow"
            :title="moodWaterTitle"
            :entry="moodWaterIngredientsWindow"
          />
        </page-section>

        <page-section
          v-if="moodPowderProducts && moodPowderProducts.length > 0"
          class="relative scheme-light"
        >
          <products
            :products="moodPowderProducts"
            float-down
            use-common-ratio
            :title="moodPowderTitle"
            :subtitle="moodPowderSubtitle"
            :min-product-slots="minProductSlots"
            class="mt-gutter"
            @over="over"
            @out="out"
          />
          <ingredients
            v-if="moodPowderIngredientsWindow"
            :title="moodPowderTitle"
            :entry="moodPowderIngredientsWindow"
          />
        </page-section>
      </color-section>

      <LazyHydrate when-idle>
        <page-section
          v-if="featuredWater && featuredWater.length > 0"
          v-observe-visibility="{ callback: showCta }"
          class="relative scheme-light"
        >
          <products
            use-common-ratio
            :products="featuredWater"
            :title="waterTitle"
            :subtitle="waterSubtitle"
            float-down
            :min-product-slots="minProductSlots"
            class="mt-gutter"
          />
          <ingredients
            v-if="waterIngredientsWindow"
            :title="waterTitle"
            :entry="waterIngredientsWindow"
          />
        </page-section>
      </LazyHydrate>

      <color-section
        force-gradient
        :background-colors="[getColor('spanish-white'), getColor('apricot')]"
      >
        <LazyHydrate when-idle>
          <page-section v-if="collabCta" full-height centered>
            <div
              class="container flex flex-wrap text-center md:text-left md:flex-no-wrap"
            >
              <div class="w-full mb-4em md:mb-0 md:w-1/2 md:flex-grow-0">
                <float
                  v-if="collabHero"
                  tag="recess-link"
                  :sanity-link="collabCta"
                >
                  <window-3d
                    skew-right
                    class="sm:text-lg md:text-xl lg:text-2xl"
                  >
                    <sanity-media v-bind="collabHero" no-ratio cover fluid />
                  </window-3d>
                </float>
              </div>
              <header class="flex-auto w-full md:pl-x-gutter">
                <h3 class="heading">
                  {{ collabTitle }}
                </h3>
                <h4
                  v-if="collabText && collabText.length > 0"
                  class="heading--sm mb-y-gutter-sm"
                >
                  <sanity-blocks :blocks="collabText" />
                </h4>
                <cta tag="recess-link" :sanity-link="collabCta" fill>
                  {{
                    collabCta.title ||
                      (collabCta.page ? collabCta.page.title : '')
                  }}
                </cta>
              </header>
            </div>
          </page-section>
        </LazyHydrate>

        <LazyHydrate when-idle>
          <page-section v-if="locatorCta" full-height centered>
            <div
              class="container flex flex-wrap items-center text-center md:text-left md:flex-no-wrap"
            >
              <header class="flex-auto w-full md:pr-x-gutter-sm">
                <h3 class="heading">
                  {{ locatorTitle }}
                </h3>
                <h4 class="heading--sm mb-y-gutter-sm">
                  <sanity-blocks
                    v-if="locatorText && locatorText.length > 0"
                    :blocks="locatorText"
                  />
                </h4>
                <cta tag="recess-link" :sanity-link="locatorCta" fill>
                  {{
                    locatorCta.title ||
                      (locatorCta.page ? locatorCta.page.title : '')
                  }}
                </cta>
              </header>
              <div
                class="order-first w-full mb-4em md:mb-0 md:order-none md:w-1/2 md:flex-grow-0"
              >
                <recess-link
                  v-if="locatorHero && (locatorHero.image || locatorHero.video)"
                  :sanity-link="locatorCta"
                  class="relative block w-full"
                >
                  <sanity-media v-bind="locatorHero" class="w-full" />
                </recess-link>
                <candy-planets v-else class="w-full" />
              </div>
            </div>
          </page-section>
        </LazyHydrate>
      </color-section>

      <color-section
        force-gradient
        :background-colors="[getColor('blue')]"
        :text-color="getColor('white')"
      >
        <LazyHydrate when-idle>
          <page-section
            v-if="featuredPress && featuredPress.length > 0"
            :title="pressTitle"
            :cta="{
              to: '/press',
              title: 'All Press',
              backgroundColor: getColor('red'),
              textColor: getColor('white'),
              borderColor: getColor('black'),
              shadowColor: getColor('pink')
            }"
            class="w-full text-white scheme-dark"
          >
            <press :press="featuredPress" />
          </page-section>
        </LazyHydrate>

        <LazyHydrate when-idle>
          <page-section
            v-if="socialMedia && socialMedia.length > 0"
            full-height
            centered
          >
            <div v-if="bp" class="wrap">
              <div class="container index__social-media">
                <div
                  v-if="bp.lg"
                  class="index__social-posts index__social-posts--lg"
                >
                  <div
                    v-for="post in socialColumns[0]"
                    :key="post._key"
                    class="index__social-post index__social-post--lg"
                  >
                    <cta
                      :tag="post.url ? 'recess-link' : 'div'"
                      :href="post.url"
                      :border-color="getColor('cloud-burst')"
                      :shadow-color="getColor('lavender')"
                      frame
                      fill
                      class="index__social-link"
                    >
                      <figure>
                        <sanity-media
                          v-bind="post.media"
                          caption="Follow us on Instagram"
                        />
                        <figcaption v-if="post.title">{{ title }}</figcaption>
                      </figure>
                    </cta>
                  </div>
                </div>
                <header class="index__social-header">
                  <h2 class="mb-y-gutter-sm xl:mb-y-gutter">
                    <recess-image
                      :src="require('~/assets/images/pretty-social.svg')"
                      alt="we're pretty social"
                      class="lg:w-full"
                    />
                  </h2>
                  <cta
                    tag="a"
                    href="https://instagram.com/takearecess"
                    title="Instagram"
                    target="_blank"
                    rel="noopener"
                    :background-color="getColor('red')"
                    :text-color="getColor('white')"
                    :border-color="getColor('cloud-burst')"
                    :shadow-color="getColor('pink')"
                    fill
                  >
                    Instagram
                  </cta>
                </header>
                <div
                  v-for="post in socialMedia"
                  :key="post._key"
                  class="index__social-post index__social-post--sm"
                >
                  <cta
                    :tag="post.url ? 'recess-link' : 'div'"
                    :href="post.url"
                    :border-color="getColor('cloud-burst')"
                    :shadow-color="getColor('lavender')"
                    frame
                    fill
                    class="index__social-link"
                  >
                    <figure>
                      <sanity-media
                        v-bind="post.media"
                        caption="Follow us on Instagram"
                      />
                      <figcaption v-if="post.title">{{ title }}</figcaption>
                    </figure>
                  </cta>
                </div>
                <div
                  v-if="bp.lg"
                  class="index__social-posts index__social-posts--lg"
                >
                  <div
                    v-for="post in socialColumns[1]"
                    :key="post._key"
                    class="index__social-post index__social-post--lg"
                  >
                    <cta
                      :tag="post.url ? 'recess-link' : 'div'"
                      :href="post.url"
                      :border-color="getColor('cloud-burst')"
                      :shadow-color="getColor('lavender')"
                      frame
                      fill
                      class="index__social-link"
                    >
                      <figure>
                        <sanity-media
                          v-bind="post.media"
                          caption="Follow us on Instagram"
                        />
                        <figcaption v-if="post.title">{{ title }}</figcaption>
                      </figure>
                    </cta>
                  </div>
                </div>
              </div>
            </div>
          </page-section>
        </LazyHydrate>
      </color-section>

      <LazyHydrate when-idle>
        <page-section
          v-if="testimonials && testimonials.length > 0"
          centered
          class="pt-0"
          title="what they're saying"
          :cta="{
            to: '/shop/collections/shopall',
            title: 'shop all products',
            backgroundColor: getColor('default-background'),
            textColor: getColor('default-text-color'),
            borderColor: getColor('default-text-color'),
            shadowColor: getColor('default-text-color')
          }"
        >
          <testimonials :testimonials="testimonials" />
        </page-section>
      </LazyHydrate>

      <LazyHydrate when-idle>
        <page-section
          v-if="klaviyoDefaultListId"
          tag="color-section"
          :background-colors="[getColor('apricot')]"
          :text-color="getColor('default-text-color')"
          full-height
          centered
          class="w-full overflow-hidden"
        >
          <div
            class="container flex flex-wrap text-center md:text-left md:flex-no-wrap"
          >
            <header
              class="flex-auto flex-grow-0 w-full md:w-2/3 md:pr-x-gutter"
            >
              <h3 class="heading">
                {{ signupTitle }}
              </h3>
              <sanity-blocks
                v-if="signupText && signupText.length > 0"
                :blocks="signupText"
                class="text-xl xl:text-2xl mb-y-gutter-sm"
              />
              <sign-up class="text-xl xl:text-2xl" />
            </header>
            <div
              class="order-first w-full mb-4em md:mb-0 md:w-1/3 md:flex-grow-0 md:order-none"
            >
              <portal class="index__portal" />
            </div>
          </div>
        </page-section>
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import chunk from 'lodash/chunk'
import { mapGetters, mapState } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'
import { meta } from '~/recess.json'
import page from '~/mixins/page'
import homeQuery from '~/sanity/queries/home'
import Splash from '~/components/splash/splash.vue'
import FeaturedProduct from '~/components/featured-product.vue'
import Ingredients from '~/components/ingredients-window.vue'
import Products from '~/components/shop/products/products.vue'
import Press from '~/components/press/press.vue'
import CandyPlanets from '~/components/candy-planets/candy-planets.vue'
import SignUp from '~/components/sign-up.vue'
import Portal from '~/components/portal.vue'
import FloatingCta from '~/components/floating-cta.vue'
import MarqueeBackground from '~/components/marquee-background.vue'

export default {
  components: {
    Splash,
    FeaturedProduct,
    Ingredients,
    Products,
    Press,
    CandyPlanets,
    SignUp,
    Portal,
    LazyHydrate,
    FloatingCta,
    MarqueeBackground
  },

  mixins: [page],

  async asyncData({ env, query, $sanity, $sentry }) {
    const samplerSplash = env.samplerSplash
    let queryId = query.preview
    if (env.nonCBDSite) {
      queryId = 'home-non-cbd'
    }
    if (samplerSplash) {
      queryId = 'home-sampler'
    }
    let home = {}
    try {
      home = await $sanity.fetch(homeQuery(queryId))
      if (!home) {
        throw new Error('Category not found')
      }
    } catch (e) {
      $sentry.captureException(e)
    }

    return home
  },

  data() {
    return {
      title: meta.title,
      titleTemplate: `${meta.title} | ${meta.tagline}`,
      splashTitle: undefined,
      splashTitleImage: undefined,
      splashText: undefined,
      splashCta: undefined,
      splashVideoWebm: undefined,
      splashVideoMov: undefined,
      splashIsVerticalLayout: undefined,
      featuredProductEnabled: undefined,
      featuredProductTitle: undefined,
      featuredProductText: undefined,
      featuredProductCta: undefined,
      featuredProductImage: undefined,
      featuredProductBackgroundImage: undefined,
      featuredProductCtaTextColor: undefined,
      featuredProductCtaButtonTextColor: undefined,
      featuredProductCtaButtonBackgroundColor: undefined,
      featuredZeroproof: undefined,
      floatingCta: undefined,
      splashHero: undefined,
      introTitle: null,
      introText: null,
      samplerTitle: 'the samplers',
      samplerSubtitle:
        'a sparkling water infused with hemp and adaptogens for calm and clarity',
      featuredSampler: [],
      waterTitle: 'we canned a feeling',
      waterSubtitle: undefined,
      moodWaterTitle: null,
      moodPowderTitle: null,
      moodProductCategory: null,
      waterIngredientsWindow: null,
      featuredWater: [],
      moodWaterProducts: [],
      moodWaterIngredientsWindow: undefined,
      moodPowderProducts: [],
      moodPowderIngredientsWindow: undefined,
      featuredRealitywear: [],
      collabTitle: null,
      collabText: [],
      collabCta: null,
      collabHero: null,
      locatorTitle: 'the store finder',
      locatorText: [],
      locatorCta: null,
      locatorHero: null,
      pressTitle: 'Recess in the press',
      featuredPress: [],
      socialMedia: [],
      signupTitle: 'sign up for our newsletter',
      signupText: null,
      samplerSplash: process.env.samplerSplash,
      ctaIsVisible: false,
      minProductSlots: 6,
      productOverBackgroundColors: undefined,
      testimonials: []
    }
  },

  computed: {
    ...mapState({
      klaviyoDefaultListId: state => state.settings?.klaviyo?.listId || null
    }),
    ...mapGetters({
      bp: 'screen/breakpoint',
      productsByCategory: 'shop/productsByCategory'
    }),
    socialColumns() {
      const media = this.socialMedia
      if (!media || !media.length) {
        return []
      }
      return chunk(media, Math.ceil(media.length / 2))
    },
    moodBackgroundHexColors() {
      return this.moodProductCategory?.backgroundColors?.map(({ hex }) => hex)
    }
  },

  mounted() {
    this.$nuxt.$emit('featuredProductEnabled', this.featuredProductEnabled)
  },

  methods: {
    // we use the splash visibility to drive the floating
    // CTA being show/hidden because there are
    // two CTA's (mobile/desktop) being rendered
    // in the splash
    showCta(visible) {
      if (visible) {
        this.ctaIsVisible = true
      }
    },
    hideCta(visible) {
      if (visible) {
        this.ctaIsVisible = false
      }
    },
    over({ colors }) {
      this.productOverBackgroundColors = colors.backgroundColors
    },
    out() {
      this.productOverBackgroundColors = undefined
    }
  }
}
</script>

<style>
.page--index .ingredient__text {
  font-size: 1.2em;
  text-align: center;
}

.index__social-media {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
}

.index__social-header {
  margin: var(--y-gutter-sm) auto;
  max-width: 75%;
  order: 3;
  text-align: center;
  width: 100%;
}

.index__social-posts--lg {
  display: none;
  flex-direction: column;
}

.index__social-post {
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  position: relative;
}

.index__social-link {
  display: block;
  width: 100%;
}

.index__social-post--sm:nth-of-type(1) {
  order: 1;
  width: 40%;
}

.index__social-post--sm:nth-of-type(2) {
  margin-bottom: 10%;
  order: 2;
  width: 50%;
}

.index__social-post--sm:nth-of-type(3) {
  margin-top: 10%;
  order: 4;
  width: 50%;
}

.index__social-post--sm:nth-of-type(4) {
  align-self: flex-start;
  order: 5;
  width: 33.3%;
}

.index__portal.portal {
  text-align: center;
}

@screen md {
  .index__portal.portal {
    text-align: right;
  }
}

@screen lg {
  .index__social-header,
  .index__social-posts--lg {
    width: 33.3%;
  }

  .index__social-header {
    height: 100%;
  }

  .index__social-posts--lg {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .index__social-posts--lg:first-child {
    padding-right: var(--x-gutter-sm);
  }

  .index__social-posts--lg:last-child {
    order: 4;
    padding-left: var(--x-gutter-sm);
  }

  .index__social-post--sm {
    display: none;
  }

  .index__social-post--lg {
    width: 100%;
  }

  .index__social-posts--lg:first-child .index__social-post--lg:nth-child(1),
  .index__social-posts--lg:first-child .index__social-post--lg:nth-child(2) {
    align-self: flex-start;
  }

  .index__social-posts--lg:first-child .index__social-post--lg:nth-child(1) {
    margin-left: 10%;
    width: 60%;
  }

  .index__social-posts--lg:first-child .index__social-post--lg:nth-child(2) {
    margin-top: var(--y-gutter);
    width: 80%;
  }

  .index__social-posts--lg:last-child .index__social-post--lg:nth-child(1) {
    align-self: flex-start;
    padding-bottom: var(--y-gutter-lg);
    width: 90%;
  }

  .index__social-posts--lg:last-child .index__social-post--lg:nth-child(2) {
    align-self: flex-end;
    padding-top: var(--y-gutter);
    width: 50%;
  }
}
</style>
