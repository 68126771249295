<template functional>
  <r-video
    ref="video"
    :style="[data.style, data.staticStyle]"
    :class="[data.class, data.staticClass]"
    :width="640"
    :height="510"
    cover
    loop
    autoplay
    class="candy-planets-video"
    src-root="/video/candy-planets"
  />
</template>

<script>
export default {
  name: 'CandyPlanetsVideo',

  functional: true
}
</script>
